import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import InspaccoLogo from "./../../../assets/images/InspaccoWhiteLogo.png";
import LogoutIcon from "@heroicons/react/24/solid/PowerIcon";
import UserCircleIcon from "@heroicons/react/24/solid/UserCircleIcon";
import EnvelopeIcon from "@heroicons/react/24/solid/EnvelopeIcon";
import PhoneIcon from "@heroicons/react/24/solid/PhoneIcon";
import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";

import { logout } from "../../../slice/authSlice";

import Button from "../../common/neomorphic/Button";
import ThemeToggle from "../../common/ThemeToggle";
import Badge from "../../common/Badge";
import { ROLES } from "../../../constants";

export default function AdminNavbar({ children, toggleDrawer }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, activeRole } = useSelector((state) => state.authSlice);

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div>
      <div className="navbar relative z-[999] transition-all duration-300 bg-primary dark:bg-base-100 text-base-100 drop-shadow-lg">
        <div className="navbar-start w-50 ">
          <div className="flex items-center inline-block px-4 text-white navbar-left">
            <img
              className="w-40 cursor-pointer"
              src={InspaccoLogo}
              alt="logo"
              onClick={() => navigate("/")}
            />{" "}
            <Badge
              text="Admin"
              color="info"
              className={"font-semibold text-base rounded-md p-3"}
            />
          </div>
          <button
            className="mx-2 btn btn-ghost dark:text-gray-400 btn-circle"
            onClick={toggleDrawer}
          >
            <Bars3Icon className="w-6 h-6" />
          </button>
        </div>
        <div className="w-1/2 navbar-end">
          <ThemeToggle />
          <label className="ml-3 mr-1 text-white btn btn-ghost text-md">
            Hi, {user?.firstName}
          </label>
          <div className="dropdown dropdown-end">
            <label
              tabIndex={0}
              className="user-profile-icon btn btn-ghost btn-circle"
            >
              <UserCircleIcon className="w-8 h-8 text-white" />
            </label>
            <div
              style={{
                position: "fixed",
                right: 5,
                top: 60,
                zIndex: 9999,
              }}
              className="mt-4 overflow-hidden shadow menu dropdown-content bg-base-100 rounded-box w-80"
            >
              <div className="flex items-center justify-between p-2 bg-primary">
                <Button
                  onClick={logoutHandler}
                  type="ghost"
                  className="gap-2 text-white hover:dark:text-accent"
                >
                  <LogoutIcon className="w-5 h-5" />
                  Logout
                </Button>
              </div>
              <div className="flex items-center justify-center gap-4 p-4">
                <div className="avatar online placeholder">
                  <div className="w-16 rounded-full bg-neutral-focus text-neutral-content">
                    <span className="text-xl">{`${user?.firstName.charAt(
                      0
                    )}${user?.lastName.charAt(0)}`}</span>
                  </div>
                </div>
                <div>
                  <div className="text-lg font-semibold text-accent">
                    {user?.firstName} {user?.lastName}
                  </div>
                  <div className="text-xs font-semibold text-accent">
                    {activeRole == ROLES.INSPACCO_KAM
                      ? "Client Super Admin"
                      : activeRole?.replace(/_/g, " ")}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 p-2 ml-2 justify-items-start">
                <div className="text-xs text-sm dark:text-white text-primary">
                  <a
                    className="flex items-start gap-2 mb-1"
                    href={`tel:${user?.mobileNumber}`}
                  >
                    <PhoneIcon className="w-4 h-4" />
                    <div>
                      <span className="font-semibold">Phone: </span>
                      <p className="mobile-number-text">
                        {user?.mobileNumber || "-"}
                      </p>
                    </div>
                  </a>
                </div>
                <div className="text-sm dark:text-white text-primary">
                  <a
                    className="flex items-start gap-2 mb-1 text-xs text-overflow ellipsis"
                    href={`to:${user?.email}`}
                  >
                    <EnvelopeIcon className="w-4 h-4" />
                    <div>
                      <span className="font-semibold">Email: </span>
                      <p>{user?.email || "-"}</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
