// import React, { useEffect, useRef, useState } from "react";
// import Button from "./Button";

// import PaperAirplaneIcon from "@heroicons/react/24/outline/PaperAirplaneIcon";
// import Loader from "../Loader";

// function ReactiveInput({
//   onSubmit,
//   value = "",
//   placeholder = "",
//   id,
//   children,
//   // loading = false,
// }) {
//   console.log("comment", value);
//   const inputRef = useRef(null);
//   const [myvalue, setMyValue] = useState(value);
//   const [isLoading , setIsLoading] = useState(false);

//   function onEnter(event) {
//     // event.preventDefault()
//     if (event.key === "Enter") {
//       console.log("onEnter", myvalue);
//       onSubmit(myvalue);
//     }
//   }

//   useEffect(() => {
//     setMyValue(value);
//   }, [value]);

//   useEffect(() => {
//     setFocusToInput();
//   }, []);

//   const setFocusToInput = () => {
//     if (inputRef.current) {
//       inputRef.current.focus();
//     }
//   };

//   const handleLoading = () =>{
//     setIsLoading(true);
//   }

//   return (
//     <div className="input-group">
//       <input
//         ref={inputRef}
//         type="text"
//         placeholder="Write here"
//         className="w-full join-item input"
//         onKeyDown={onEnter}
//         value={myvalue}
//         onChange={(e) => setMyValue(e.target.value)}
//       />
//       <Button
//         className="gap-2 join-item"
//         // loading={isLoading}isLoading
//         onClick={(e) => {
//           onSubmit(myvalue);
//           setMyValue("");
//           handleLoading();
//         }}
//         // disabled={loaisLoadingding}
//       >
//         {isLoading ? (
//           <Loader />
//         ) : (
//           <>
//             <PaperAirplaneIcon className="w-4 h-4 -rotate-45" />
//           </>
//         )}
//         Comment
//       </Button>
//       {children}
//     </div>
//   );
// }

// export default ReactiveInput;



import React, { useEffect, useRef, useState } from "react";
import Button from "./Button";
import PaperAirplaneIcon from "@heroicons/react/24/outline/PaperAirplaneIcon";
import Loader from "../Loader";

function ReactiveInput({
  onSubmit,
  value = "",
  placeholder = "",
  id,
  children,
}) {
  const inputRef = useRef(null);
  const [myvalue, setMyValue] = useState(value);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setMyValue(value || "");
  }, [value]);

  useEffect(() => {
    setFocusToInput();
  }, []);

  const setFocusToInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleSubmit = async () => {
    if (!myvalue.trim()) return; 
    setIsLoading(true);
    try {
      await onSubmit(myvalue); 
      setMyValue("");
    } catch (error) {
      console.error("Submission error:", error);
    } finally {
      setIsLoading(false); 
    }
  };

  const onEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); 
      handleSubmit();
    }
  };

  return (
    <div className="input-group">
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholder || "Write here"}
        className="w-full join-item input"
        onKeyDown={onEnter}
        value={myvalue}
        onChange={(e) => setMyValue(e.target.value)}
        disabled={isLoading} 
      />
      <Button
        className="gap-2 join-item"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <PaperAirplaneIcon className="w-4 h-4 -rotate-45" />
          </>
        )}
        Comment
      </Button>
      {children}
    </div>
  );
}

export default ReactiveInput;
