import DocumentTextIcon from "@heroicons/react/24/outline/DocumentTextIcon";
import XCircleMiniIcon from "@heroicons/react/20/solid/XCircleIcon";

import { _openNewTab, isImage } from "../../../../helpers/utils";
import DocumentViewer from "../DocumentViewer";

export default function DocumentItem(node) {
  console.log("new url", node.url);
  return (
    <>
      {isImage(node.url) ? (
        <DocumentViewer />
      ) : (
        <div
          onClick={() => {
            if (node?.openUrl) {
              _openNewTab(node.url);
            }
          }}
          className="flex items-center w-48 h-12 p-2 transition-all duration-300 rounded-md cursor-pointer hover:bg-base-300 bg-base-100 jusify-center"
        >
          <DocumentTextIcon className="w-8 h-8" />
          <div className="mx-2 overflow-hidden text-xs text-left truncate text-ellipsis">
            <div className="font-medium">{node.fileName}</div>
            <div>{node.createdAt}</div>
          </div>
          {/* <div className="self-start w-2">
            <XCircleMiniIcon className="w-4 h-4" />
          </div> */}
        </div>
      )}
    </>
  );
}
